<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row>
        <b-col lg="6">
          <b-card title="Filtre transações">
            <b-row>
              <b-col>
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer selected"
                  value="0"
                >
                  Lib em Conf.
                </b-form-radio>
              </b-col>
              <b-col>
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="1"
                >
                  Pendente
                </b-form-radio>
              </b-col>
              <b-col>
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="2"
                >
                  Liberado
                </b-form-radio>
              </b-col>

            </b-row>
          </b-card>
        </b-col>

        <b-col>
          <b-card title="Data inicial">
            <template>
             <b-form-datepicker
              v-model="from"
              locale="pt-br"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
             />
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Data final">
            <template>
              <b-form-datepicker
                v-model="to"
                locale="pt-br"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
              />
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <deposits-list
            v-if="userNames"
            :extra-fields="extraFields"
            :orders="deposits_orders.data"
            :user-names="userNames"
            :context-actions="'admin'"
            @openSidebar="openTransactionSidebar"
          />
          <nav aria-label="...">
            <ul class="pagination">
              <li
                v-if="deposits_orders.paginatorInfo.currentPage - 1 > 0"
                class="page-item"
              >
                <span
                  class="page-link pointer"
                  @click="paginate(-1)"
                >Anterior</span>
              </li>
              <li class="page-item active">
                <span class="page-link">
                  {{ deposits_orders.paginatorInfo.currentPage }}
                </span>
              </li>
              <li
                v-if="deposits_orders.paginatorInfo.currentPage < deposits_orders.paginatorInfo.lastPage"
                class="page-item"
              >
                <span
                  class="page-link pointer"
                  @click="paginate(+1)"
                >Proxima</span>
              </li>
            </ul>
          </nav>
        </b-col>
      </b-row></div>
    <div v-else>
      <logo-loader-animation />
    </div>
    <edit-transaction-sidebar
      ref="transactionSidebar"
      :transaction="transactionObj"
      @refreshOrders="refreshOrders()"
    />
  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio,BFormDatepicker
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import DepositsList from '@/components/transactions/DepositsList.vue'
import EditTransactionSidebar from '@/components/transactions/EditTransactionSidebar.vue'

export default {
  components: {
    BCard,
    Datepicker,
    BCardText,
    BLink,
    GeneralGroupsList,
    underscore: _,
    LogoLoaderAnimation,
    DepositsList,
    EditTransactionSidebar,
    BFormRadio,
    BCol,
    BRow,
    BFormDatepicker
  },
  data() {
    return {
      from: new Date().toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      pageToSearch: 1,
      queryResult: null,
      transactionObj: null,
      transactionStatus: 0,
      statusName: ['CONFIRMED_WITH_OBSERVATION', 'PENDING', 'CONFIRMED'],
      userNames: null,
      extraFields: ['id', { key: 'amount', label: 'Valor', formatter: 'currency' }, { key: 'user_id', label: 'Solicitante', formatter: 'depositOwner' }, { key: 'provider.code', label: 'Método' }, { key: 'created_at', label: 'Aberto em', formatter: 'dateFormatter' }, { key: 'tags', label: 'Info Adicional', formatter: 'pendingInfo' }, { key: 'updated_at', label: 'Atualiz. em', formatter: 'dateFormatter' }, { key: 'status', label: 'Status' },  { key: 'promocode', label: 'Promocode' }, { key: 'actions', label: 'Ações' }],
    }
  },
  apollo: {
    deposits_orders: {
      query: gql`query($status:String, $offset:Int, $from: Mixed, $to: Mixed){
      deposits_orders: 
        orders(first:30,order_type:"DEPOSIT",status:$status, page:$offset, where: {column:CREATED_AT, operator:GTE, value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
          data{
            id,
            order_type,
            user_id,
            amount
            status,
            created_at,
            updated_at,
            promocode,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
          paginatorInfo{
            currentPage,
            lastPage
          }
        }
      }`,
      client: 'cashioClient',
      variables() {
        return {
          status: this.statusName[this.transactionStatus],
          offset: this.pageToSearch,
          from: this.from,
          to: this.to,
        }
      },
    },
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  watch: {
    deposits_orders() {
      const obj = _.pluck(this.deposits_orders.data, 'user_id')
      this.user_ids = [...new Set(obj)]
      this.$apollo.query({
        query: gql`query ($user_ids: [ID!]) {
          get_users_doc(user_ids: $user_ids, type:"FULLNAME") {
            user_id
            value
          }
        }`,
        variables: {
          user_ids: this.user_ids,
        },
        client: 'identityClient',
      }).then(data => {
        this.userNames = data.data.get_users_doc
      }).catch(error => {
        console.error(error)
      })
    },
  },
  created() {
    // this.searchUser()
  },
  methods: {
    openTransactionSidebar(e) {
      this.transactionObj = e
      this.$refs.transactionSidebar.toogleV()
    },
    paginate(eV) {
      if(this.pageToSearch == 1){
        this.pageToSearch = 2;

      }else{
        this.pageToSearch = this.pageToSearch + eV;
      }
    },
  },
}
</script>

<style>

</style>
